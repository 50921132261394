import {
    json,
    redirect
} from '@remix-run/node'
import slugify from 'slugify'

import getApp from '~/utils/getApp'
import getScope from '~/utils/scope'

import itemResolver from '~/services/resolvers/item'
import metaResolver from '~/services/resolvers/meta'

import getFamilyType from '~/utils/getFamilyType'
import serverErrorHandler from '~/utils/serverErrorHandler'

import {
    cleanUrl,
    cleanAppFromUrl
} from '~/utils/helpers'

import {
    MarketGuide as MarketGuideApp,
    Jobs as JobsApp
} from '~/config/apps'

import type {
    LoaderFunction,
    MetaFunction
} from '@remix-run/node'

import type { CompanyPayload } from '~/modules/marketGuide/types'
import type { JobPayload } from '~/modules/jobs/types'

export const loader: LoaderFunction = async ({ request, params }) => {
    if (params.id) {
        const url = request.url
        const app = getApp(url)

        if (app instanceof Response) {
            return app
        }

        const scope = getScope(app, request.url)

        if (scope instanceof Response) {
            return scope
        }

        try {
            const { result, _type } = await itemResolver({
                app: app,
                url: url,
                scope: scope,
                id: params.id
            })

            /**
             * This is a temporarily redirect due to a change in the routes structure
             * This should be removed once we make sure that no one is using the old routes for
             *      * Market pages
             *      * Job pages
             */
            if (
                app.id === MarketGuideApp.id ||
                app.id === JobsApp.id 
            ) {
                const parsedUrl = new URL(url)


                const companyName = (
                    app.id === JobsApp.id ? (
                        (result as JobPayload).companyName
                    ) : (
                        (result as CompanyPayload).name
                    )
                ) || 'UNKNOWN'

                const encodedCompanyName = slugify(companyName, {
                    locale: 'sv'
                })

                const searchParams = parsedUrl.search ? parsedUrl.search : ''

                return redirect(`//${app.path}${cleanAppFromUrl(cleanUrl(parsedUrl.host))}/${encodedCompanyName}/${params.id}${searchParams}`)
            }
            
            return json({
                _type: _type,
                app: app,
                scope: scope,
                payload: result
            })
        } catch (error: any) {
            const _type = getFamilyType(url, scope.id)

            await serverErrorHandler(request, error, scope.id)

            return json({
                _type: _type,
                app: app,
                scope: scope,
                payload: {}
            })
        }
    } else {
        throw new Response('Item not found!', {
            status: 404
        })
    }
}

export const meta: MetaFunction = ({ data, location }) => {
    const metaPayload = metaResolver(data, location)

    return metaPayload
}

export { default } from '~/pages/ItemPage'